import Api from '../Api'

class ShippingApi extends Api {
    postList(token, shop_id, limit, offset, conditions, responseType) {
        const objectRequest =   {
            "limit": responseType ? offset  : (limit ? limit : 50),
            "offset": responseType ? 0 : (offset ? offset : 0),
            "conditions": conditions ? conditions : null,
            "response_type": responseType ? responseType : null
        }
        if (shop_id !== "all") {
            objectRequest.shop_id = shop_id ? shop_id : 1
        }

        return this.http.post('all-order-tab/shipped',
            objectRequest,
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getShippingDetails(token, shop_id, order_id) {
        return this.http.post('order/detail/shipping',
            {
                "order_id": order_id,
                "shop_id": shop_id ? shop_id : 1
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    postShippingDetailsEdit(token, order_id, name, phone, country_id, address_line1, address_line2, address_line3, city, state_or_region, postal_code, shipping_price, tax_amount) {
        return this.http.post('order/details/edit',
            {
                "order_id": order_id,
                "name": name,
                "phone": phone,
                "country_id": country_id,
                "address_line1": address_line1,
                "address_line2": address_line2,
                "address_line3": address_line3,
                "city": city,
                "state_or_region": state_or_region,
                "postal_code": postal_code,
                "shipping_price": shipping_price,
                "tax_amount": tax_amount
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    postShippingCreateDeclaration(token, order_id, asin, title, packaged_quantity, country_id, item_value, hs_code) {
        return this.http.post('order/details/create-declaration',
            {
                "order_id": order_id,
                "asin": asin,
                "title": title,
                "packaged_quantity": packaged_quantity,
                "country_id": country_id,
                "item_value": item_value,
                "hs_code": hs_code
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    getManifestByDate(token, date) {
        return this.http.post('delivery/manifests',
            {
                "date": date
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            })
    }

    ePostManifest(token) {
        return this.http.get('delivery/close-packages',
        {
            headers: {
                "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
            }
        })
    }
    createManifest(token) {
        return this.http.get('delivery/create-manifest', //fix:method GET should be replaced on post on backend side
        {
            headers: {
                "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
            }
        })
    }
}

export default new ShippingApi()