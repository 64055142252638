import ShippingApi from '../../api/OrdersApi/ShippingApi'
import Notify from '../../../views/components/notify/Notify'
import {LogOutOnAuthError, CheckAndNotifyErrors, CheckAndNotify} from './ordersReducer'

const SET_MANIFEST = 'SHIPPING/SET_MANIFEST'
const SET_CREATE_MANIFEST = 'SHIPPING/SET_CREATE_MANIFEST'

const initialState = {
    ManifestData: {}
}

const manifestReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MANIFEST:
            return  {
                ...state,
                ManifestData: action.payload
            }
        case SET_CREATE_MANIFEST:
            Notify('Record(s) has been added', 'Success', 'success')
            return  {
                ...state
            }
        default:
            return state
    }
}

export const setManifest = (payload) => ({type: SET_MANIFEST, payload})
export const setCreateManifest = (payload) => ({type: SET_CREATE_MANIFEST, payload})

export const getManifestByDate = (date) => (dispatch, getState) => {
    ShippingApi.getManifestByDate(getState().auth.AuthToken, date)
        .then(response => {
            CheckAndNotify(response)
            dispatch(setManifest(response.data.data))
        }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const createManifest = (isFetching) => (dispatch, getState) => {
    isFetching(true)
    ShippingApi.createManifest(getState().auth.AuthToken)
        .then(response => {
            CheckAndNotify(response)
            dispatch(setCreateManifest(response.data.data))
        }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    }).finally(() => {
        isFetching(false)
    })
}

export const ePostManifest = () => (dispatch, getState) => {
    ShippingApi.ePostManifest(getState().auth.AuthToken)
        .then(response => {
            CheckAndNotify(response)
            Notify(response.data.data.message, 'Success', 'success')
        }).catch(error => {
            if (error) {
                dispatch(LogOutOnAuthError(error))
                CheckAndNotifyErrors(error)
            }
        })
}

export default manifestReducer