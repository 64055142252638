import SettingsApi from "../../api/SettingsApi/SettingsApi"
import { SET_ORDER_ITEM_BIN_LIST, SET_PACKING_SLIP_LABELS } from "../../constants/constants"
import {
  CheckAndNotify,
  CheckAndNotifyErrors,
  LogOutOnAuthError
} from "../orders/ordersReducer"

const initialState = {
  orderItemBinList: [],
  packingSlipLabels: []
}

const OrderItemBinReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDER_ITEM_BIN_LIST:
      return {
        ...state,
        orderItemBinList: action.list
      }
    case SET_PACKING_SLIP_LABELS:
      return {
        ...state,
        packingSlipLabels: action.list
      }

    default:
      return state
  }
}

export const setOrderItemBinList = (list) => ({
  type: SET_ORDER_ITEM_BIN_LIST,
  list
})

export const setPackingSlipLabels = (list) => ({
  type: SET_PACKING_SLIP_LABELS,
  list
})

export const getOrderItemBinList = (props) => (dispatch, getState) => {
  SettingsApi.getOrderItemBinList(getState().auth.AuthToken, props)
    .then((response) => {
      CheckAndNotify(response)
      dispatch(setOrderItemBinList(response.data.data))
    })
    .catch((error) => {
      if (error) {
        dispatch(LogOutOnAuthError(error))
        CheckAndNotifyErrors(error)
      }
    })
}

export const updateOrderItemBinList = (props) => (dispatch, getState) => {
  SettingsApi.updateOrderItemBin(getState().auth.AuthToken, props)
    .then((response) => {
      CheckAndNotify(response)
      dispatch(getOrderItemBinList({
        conditions: {},
        limit: 10000,
        offset: 0
      }))

    })
    .catch((error) => {
      if (error) {
        dispatch(LogOutOnAuthError(error))
        CheckAndNotifyErrors(error)
      }
    })
}

export const getPackingSlipLabels = (ordersId) =>  (dispatch, getState) => {
    let allRequestsArray = []
    let packingSlipLabel = []
    ordersId.map(item => {
        allRequestsArray.push(SettingsApi.getPackingSlipLabels(getState().auth.AuthToken, item))
    })
    Promise.all(allRequestsArray).then((resonses) => {
        resonses.map(r => {
            packingSlipLabel.push(...r.data.data)
        })

        dispatch(setPackingSlipLabels(packingSlipLabel))
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export default OrderItemBinReducer
