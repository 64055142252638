import Api from '../Api'

class OrdersApi extends Api {

    postList(token, shop_id, limit, offset, conditions, responseType) {
        if (shop_id === "all") {
            return this.http.post('all-order-tab/awaiting',
                {
                    "limit": responseType ? offset  : (limit ? limit : 50),
                    "offset": responseType ? 0 : (offset ? offset : 0),
                    "conditions": conditions ? conditions : null,
                    "response_type": responseType ? responseType : null
                },
                {
                    headers: {
                        "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                    }
                }
            )
        } else {
            return this.http.post('order-tab/awaiting',
                {

                    "limit": responseType ? offset  : (limit ? limit : 50),
                    "offset": responseType ? 0 : (offset ? offset : 0),
                    "conditions": conditions ? conditions : null,
                    "shop_id": shop_id ? shop_id : 1,
                    "response_type": responseType ? responseType : null
                },
                {
                    headers: {
                        "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                    }
                }
            )
        }
    }

    postReceivedList(token, shop_id, limit, offset, conditions, responseType) {
        if (shop_id === "all") {
            return this.http.post('all-order-tab/received',
                {
                    "limit": responseType ? offset  : (limit ? limit : 50),
                    "offset": responseType ? 0 : (offset ? offset : 0),
                    "conditions": conditions ? conditions : null,
                    "response_type": responseType ? responseType : null
                },
                {
                    headers: {
                        "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                    }
                }
            )
        } else {
            return this.http.post('order-tab/received',
                {
                    "limit": responseType ? offset  : (limit ? limit : 50),
                    "offset": responseType ? 0 : (offset ? offset : 0),
                    "conditions": conditions ? conditions : null,
                    "shop_id": shop_id ? shop_id : 1,
                    "response_type": responseType ? responseType : null
                },
                {
                    headers: {
                        "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                    }
                }
            )
        }
    }
    postPrintLateList(token, shop_id, limit, offset, conditions, responseType) {
        const objectRequest =   {
            "limit": responseType ? offset  : (limit ? limit : 50),
            "offset": responseType ? 0 : (offset ? offset : 0),
            "conditions": conditions ? conditions : null,
            "response_type": responseType ? responseType : null
        }
        if (shop_id !== "all") {
            objectRequest.shop_id = shop_id ? shop_id : 1
        }

        return this.http.post('all-order-tab/print-late',
            objectRequest,
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    postReturnRequestList(token, shop_id, limit, offset, conditions, responseType) {
        if (shop_id === "all") {
            return this.http.post('all-order-tab/return-request',
                {
                    "limit": responseType ? offset  : (limit ? limit : 50),
                    "offset": responseType ? 0 : (offset ? offset : 0),
                    "conditions": conditions ? conditions : null,
                    "response_type": responseType ? responseType : null
                },
                {
                    headers: {
                        "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                    }
                }
            )
        } else {
            return this.http.post('order-tab/return-request',
            {
                "limit": responseType ? offset  : (limit ? limit : 50),
                "offset": responseType ? 0 : (offset ? offset : 0),
                "conditions": conditions ? conditions : null,
                "shop_id": shop_id ? shop_id : 1,
                "response_type": responseType ? responseType : null
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
        }
    }

    postReturnList(token, shop_id, limit, offset, conditions, responseType) {
        if (shop_id === "all") {
            return this.http.post('all-order-tab/return',
                {
                    "limit": responseType ? offset  : (limit ? limit : 50),
                    "offset": responseType ? 0 : (offset ? offset : 0),
                    "conditions": conditions ? conditions : null,
                    "response_type": responseType ? responseType : null
                },
                {
                    headers: {
                        "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                    }
                }
            )
        } else {
            return this.http.post('order-tab/return',
            {
                "limit": responseType ? offset  : (limit ? limit : 50),
                "offset": responseType ? 0 : (offset ? offset : 0),
                "conditions": conditions ? conditions : null,
                "shop_id": shop_id ? shop_id : 1,
                "response_type": responseType ? responseType : null
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
        }
    }

    postCanceledList(token, shop_id, limit, offset, conditions, responseType) {
        const objectRequest =   {
            "limit": responseType ? offset  : (limit ? limit : 50),
            "offset": responseType ? 0 : (offset ? offset : 0),
            "conditions": conditions ? conditions : null,
            "response_type": responseType ? responseType : null
        }
        if (shop_id !== "all") {
            objectRequest.shop_id = shop_id ? shop_id : 1
        }

        return this.http.post('all-order-tab/cancel',
            objectRequest,
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    postNewList(token, shop_id, limit, offset, conditions, responseType) {
        const objectRequest =   {
            "limit": responseType ? offset  : (limit ? limit : 50),
            "offset": responseType ? 0 : (offset ? offset : 0),
            "conditions": conditions ? conditions : null,
            "response_type": responseType ? responseType : null
        }
        if (shop_id !== "all") {
            objectRequest.shop_id = shop_id ? shop_id : 1
        }

        return this.http.post('all-order-tab/new',
            objectRequest,
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    postInvalidOrdersList(token, shop_id, limit, offset, conditions, responseType) {
            return this.http.post('invalid-order-tab',
                {
                    "limit": responseType ? offset  : (limit ? limit : 50),
                    "offset": responseType ? 0 : (offset ? offset : 0),
                    "conditions": conditions ? conditions : null,
                    "response_type": responseType ? responseType : null
                },
                {
                    headers: {
                        "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                    }
                }
            )
    }

    getOrderDetails(token, shop_id, order_id) {
        return this.http.post('order/detail',
            {
                "order_id": order_id,
                "shop_id": shop_id ? shop_id : 1
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    postOrderDetailsEdit(token, order_id, name, phone, country_id, address_line1, address_line2, address_line3, city, state_or_region, postal_code, total_shipping_price, personal_data_tax_id) {
        return this.http.post('order/edit',
            {
                "order_id": order_id,
                "name": name,
                "phone": phone,
                "country_id": country_id,
                "address_line1": address_line1,
                "address_line2": address_line2,
                "address_line3": address_line3,
                "city": city,
                "state_or_region": state_or_region,
                "postal_code": postal_code,
                "total_shipping_price": total_shipping_price,
                "buyer_tax_id": personal_data_tax_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    postGetDeclaration(token, label_id) {
        return this.http.post('/order/get-label-declaration',
            {
                "label_id": label_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    postOrderUpdateDeclaration(token, order_item_id, order_id, asin, title, packaged_quantity, country_id, item_value, hs_code, SKU) {
        return this.http.post('/order/update-order-item-declaration',
            {
                "order_item_id": order_item_id,
                "asin": asin,
                "title": title,
                "packaged_quantity": packaged_quantity,
                "country_id": country_id,
                "item_value": item_value,
                "hs_code": hs_code,
                "seller_sku": SKU
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    getDimWeightRateCarrier(token, order_id, package_weight_value, package_weight_unit_of_measure, package_dimension_length,
                             package_dimension_width, package_dimension_height, package_dimension_unit_of_measure,
                             delivery_service_type_id) {
        return this.http.post('/dim-weight/rate-carrier',
            {
                "order_id": order_id,
                "package_weight_value": package_weight_value,
                "package_weight_unit_of_measure": package_weight_unit_of_measure,
                "package_dimension_length": package_dimension_length,
                "package_dimension_width": package_dimension_width,
                "package_dimension_height": package_dimension_height,
                "package_dimension_unit_of_measure": package_dimension_unit_of_measure,
                "delivery_service_type_id": delivery_service_type_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    getDimWeightRateCarrierAuto(token, order_id, package_weight_value, package_weight_unit_of_measure, package_dimension_length,
                            package_dimension_width, package_dimension_height, package_dimension_unit_of_measure) {
        return this.http.post('/dim-weight/rate-carrier-auto',
            {
                "order_id": order_id,
                "package_weight_value": package_weight_value,
                "package_weight_unit_of_measure": package_weight_unit_of_measure,
                "package_dimension_length": package_dimension_length,
                "package_dimension_width": package_dimension_width,
                "package_dimension_height": package_dimension_height,
                "package_dimension_unit_of_measure": package_dimension_unit_of_measure
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    postCheckCalculatorPrice(token, order_id, package_weight_value, package_weight_unit_of_measure, package_dimension_length,
                             package_dimension_width, package_dimension_height, package_dimension_unit_of_measure,
                             delivery_service_type_id, delivery_package_id, bill_duties_taxes) {
        return this.http.post('/delivery/cost/internal',
            {
                "order_id": order_id,
                "package_weight_value": package_weight_value,
                "package_weight_unit_of_measure": package_weight_unit_of_measure,
                "package_dimension_length": package_dimension_length,
                "package_dimension_width": package_dimension_width,
                "package_dimension_height": package_dimension_height,
                "package_dimension_unit_of_measure": package_dimension_unit_of_measure,
                "delivery_service_type_id": delivery_service_type_id,
                "delivery_package_id": delivery_package_id,
                "bill_duties_taxes": bill_duties_taxes
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    postCreateLabel(token, shop_id, order_items, package_weight_value, package_weight_unit_of_measure, package_dimension_length,
                             package_dimension_width, package_dimension_height, package_dimension_unit_of_measure,
                             delivery_service_type_id, delivery_package_id, rate, delivery_date, bill_duties_taxes, enable_tax_id, confirmation, service_custom_fields, is_scales, dimension_method_id, is_final_shipping_label) {
        return this.http.post('delivery/create-label',
            {
                "order_items": order_items,
                "package_weight_value": package_weight_value,
                "package_weight_unit_of_measure": package_weight_unit_of_measure,
                "package_dimension_length": package_dimension_length,
                "package_dimension_width": package_dimension_width,
                "package_dimension_height": package_dimension_height,
                "package_dimension_unit_of_measure": package_dimension_unit_of_measure,
                "delivery_service_type_id": delivery_service_type_id,
                "delivery_package_id": delivery_package_id,
                "bill_duties_taxes": bill_duties_taxes,
                "enable_tax_id": enable_tax_id,
                "delivery_service_confirmation_type_id" : confirmation,
                "shop_id": shop_id ? shop_id : 1,
                "service_custom_fields": service_custom_fields,
                "is_scales": is_scales,
                "dimension_method_id": dimension_method_id,
                "is_final_shipping_label": is_final_shipping_label
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    deleteDeclaration(token, order_item_id, order_id, asin) {
        return this.http.delete('/order/delete-order-declaration',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                },
                data:  {
                    "order_item_id": order_item_id
                }
            }
        )
    }

    scanBarCode(token, key_name, key_value) {
        return this.http.post('/shipping-office/package/find-purchase-order-by-params',
                {
                    "key_name": key_name,
                    "key_value": key_value
                },
            {
            headers: {
                "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    importOrders(token, file, shop_id) {
        return this.http.post('/order/upload-new-order-from-txt-file', file,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    addManualOrder(token, order_id, name, phone, country_id,
                   address_line1, address_line2, address_line3,
                   city, state_or_region, postal_code, ship_by, order_items) {
        return this.http.post('order/create',
            {
                "order_id": order_id,
                "name": name,
                "phone": phone,
                "country_id": country_id,
                "address_line1": address_line1,
                "address_line2": address_line2,
                "address_line3": address_line3,
                "city": city,
                "state_or_region": state_or_region,
                "postal_code": postal_code,
                "ship_by": ship_by,
                "order_items": order_items
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    getHsCodes(token) {
        return this.http.get('manual/hs-codes',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')                }
            }
        )
    }

    getLabelsList(token, order_id) {
        return this.http.post('/delivery/list-label',
            {
                "order_id": order_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    voidLabel(token, label_id) {
        return this.http.post('/delivery/void-label',
            {
                "label_id": label_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    getShopsList(token) {
        return this.http.get('order/get-shops',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    exportLabels(token, date) {
        return this.http.post('delivery/export-labels',
            {
                "date": date
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    editOrderItemStatus(token, item_id, status_id, page_id) {
        return this.http.post('order-item/edit-order-item-status',
            {
                "order_item_id": item_id,
                "order_item_status_id": status_id,
                "page_id": page_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }


    postOrderChatList(token, order_id) {
        return this.http.post('order/chat-order-message',
            {
                "order_id": order_id
                },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    getOrderCustomList(token) {
        return this.http.get('manual/delivery-service-types',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            })
    }

    getCustomFields(token) {
        return this.http.get('manual/service-custom-fields',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            })
    }

    postCreateOrderMessage(token, order_id, message) {
        return this.http.post('order/create-order-message',
            {
                "order_id": order_id,
                "message": message
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    postOrderTabsCount(token, shop_id) {
        return this.http.post('order/status-count',
            {
                "shop_id": shop_id !== "all" ? shop_id : null
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    postOrderTabsCountAllOrders(token, shop_id) {
        return this.http.get('all-order/status-count',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getOrderTabsCountByConditions(token, tab_name, obj) {
        return this.http.post(`order/count-${tab_name}`,
            obj,
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    postCreateInvoice(token, order_id, tracker_number, enable_tax_id) {
        return this.http.post('delivery/invoice/create',
            {
                "order_id": order_id,
                "tracker_number": tracker_number,
                "enable_tax_id": enable_tax_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    AutoCreateOrderDeclaration(token, order_item_id) {
        return this.http.post('/order/auto-create-order-declaration',
            {
                "order_item_id": order_item_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    AutoCreateOrderDeclarationManual(token, order_item_id) {
        return this.http.post('/order/auto-create-order-declaration-manual',
            {
                "order_item_id": order_item_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    AutoCreateLabel(token, order_items, package_dimension_length, package_dimension_width, package_dimension_height, package_weight_value, is_scales, is_scan_page) {
        return this.http.post('delivery/auto-create-label',
        {
            "order_items": order_items,
            package_dimension_length,
            package_dimension_width,
            package_dimension_height,
            package_weight_value,
            is_scales,
            is_scan_page
        },
        {
            headers: {
                "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
            }
        }
    )
    }

    setReturnItemStatus(token, order_item_id) {
        return this.http.post('scan-page/set-return-item-status',
            {
                "order_item_id": order_item_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    } 

    ReportByRange(token, date_from, date_to, type_of_report) {
        return this.http.post('/orders/shipped-list/report',
            {
                "date_from": date_from,
                "date_to": date_to,
                "type_of_report": type_of_report
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    ReturnRequestReportByRange(token, date_from, date_to, type) {
        return this.http.post('/orders/return-request/report',
            {
                "date_from": date_from,
                "date_to": date_to,
                "type": type
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    getDownloadReturnUploadLabelsReport(token, date_from, date_to, return_uploads_labels_report_type_id) {
        return this.http.post('/orders/return-uploads-labels/report',
            {
                "date_from": date_from,
                "return_uploads_labels_report_type_id": return_uploads_labels_report_type_id,
                "date_to": date_to
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    
    ReportCancelledByRange(token, date_from, date_to) {
        return this.http.post('/orders/cancelled-scan-page/report',
            {
                "date_from": date_from,
                "date_to": date_to
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    ReportCancelledAllByRange(token, date_from, date_to) {
        return this.http.post('/orders/cancelled-all/report',
            {
                "date_from": date_from,
                "date_to": date_to
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getCompanyShopsList(token) {
        return this.http.get('/order/get-company-shops',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    uploadPersonalData(token, file, shop_id) {
        return this.http.post('/order/upload-personal-data', file,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    getDataFromScales(token, orderItemId) {
        return this.http.get('storage-scales?order_item_id=' + orderItemId,
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    printBulkOrders(token, orders_ids_array) {
        return this.http.post('/delivery/orders-auto-create-label',
            {
                "orders_id": orders_ids_array
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    PrintBulkOrdersByParams(token, company_ids, marketplace_ids, shop_ids, status, ship_by_from, ship_by_to, order_date_from, order_date_to) {
        let paramsObj = {}
        if (company_ids) {
            paramsObj.company_ids = company_ids
        }
        if (marketplace_ids) {
            paramsObj.marketplace_ids = marketplace_ids
        }
        if (shop_ids) {
            paramsObj.shop_ids = shop_ids
        }
        if (status) {
            paramsObj.status = status
        }
        if (ship_by_from && ship_by_to) {
            paramsObj.ship_by = {from: ship_by_from, to: ship_by_to}
        }
        if (order_date_from && order_date_to) {
            paramsObj.order_date = {from: order_date_from, to: order_date_to}
        }

        return this.http.post('/delivery/orders-auto-create-label-by-params',
            paramsObj,
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getOrderItemsProblems(token, order_id) {
        return this.http.post('/order-item/problem',
            {
                "order_id": order_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    setOrderItemTag(token, orders, tag_id) {
        return this.http.post('/order-item-tag/add',
            {
                "orders":orders,
                "tag_id": tag_id,
                'manager_comment': ''
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    deleteOrderItemTag(token, orders, tag_id) {
        return this.http.delete('/order-item-tag/remove',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                },
                data:  {
                    "orders":orders,
                    "tag_id": tag_id
                }
            }
        )
    }

    getReturnLabelsList(token, order_id) {
        return this.http.post('/delivery/return/list-return-label',
            {
                "order_id": order_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getOrdersValidationErrors(token, order_id) {
        return this.http.post('/order-validation-error/find',
            {
                "order_id": order_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    // https://alpha.canadamin.com/api/manual/order-item-tag/list

    getOrderItemTagList(token) {
        return this.http.post('/manual/order-item-tag/list',
            {
                conditions: {}
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
}

export default  new OrdersApi()